import React, { type FC, useMemo, useState } from 'react';

import { useTheme } from 'styled-components';

import { Avatar, Divider, Flex, Heading, Spacer, Table, Text } from '@uiKit';

import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize } from '@uiKit/Heading/types';
import { TableBody, TableCell, TableRow } from '@uiKit/Table/components';
import { TextSize } from '@uiKit/Text/types';

import { LoaderScreen, RadioGroup, RadioText } from '@component/index';
import { useAPI } from '@hooks/useAPI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { PageLayout } from '@layouts/PageLayout';
import { getTeam } from '@lib/api';

export const Team: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  const { data, isLoading } = useAPI({ apiService: getTeam }, { runOnMount: true });
  const groups = new Set(data?.team.map((v) => v.group));

  const [selectedGroup, setSelectedGroup] = useState('ЦТКИО');
  const onChange = (value: string) => { setSelectedGroup(value); };

  const selectedGroupData = useMemo(() => data?.team.filter(({ group }) => group === selectedGroup), [selectedGroup, data?.team]);

  return (
    isLoading ? <LoaderScreen /> : (
      <PageLayout>
        <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
        <Heading size={isMobile ? HeadingSize.DF : HeadingSize.LG} align={HeadingAlign.CENTER}>Команда</Heading>
        <Spacer space={theme.spacings.x24} />
        <Flex justifyContent={FlexJustifyContent.CENTER}>
          <RadioGroup name='group' value={selectedGroup} onChange={onChange} gap={theme.spacings.x36}>
            {[...groups].map((group, index) => (
              <RadioText key={index} value={group}>
                {group}
              </RadioText>
            ))}
          </RadioGroup>
        </Flex>
        <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
        <Table rowSpace={theme.spacings.x24} fullWidth>
          <TableBody>
            {selectedGroupData ? selectedGroupData.map(({ name, imageMin, post, desc }, index) => (
              <>
                <TableRow>
                  <TableCell>
                    <Flex direction={FlexDirection.ROW} alignItems={FlexAlignItems.CENTER}>
                      <Avatar src={imageMin} />
                      <Spacer space={theme.spacings.x36} />
                      <Flex direction={FlexDirection.COLUMN}>
                        <Text size={TextSize.M1} fontWeight={600}>{name}</Text>
                        <Text size={TextSize.S3} color={theme.colors.base.grey[700]}>{post}</Text>
                      </Flex>
                    </Flex>
                  </TableCell>
                  <TableCell align={isMobile ? undefined : 'right'}>
                    <Text size={TextSize.S3}>{desc}</Text>
                  </TableCell>
                </TableRow>
                {index !== selectedGroupData.length - 1 && <Divider style={{ position: 'absolute' }} />}
              </>
            )) : <></>}
          </TableBody>
        </Table>
        <Spacer space={theme.spacings.x128 + theme.spacings.x128} />
      </PageLayout>
    )
  );
};
