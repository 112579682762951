import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { type NewsRequest, type NewsResponse } from './types';

export const getNews = async (
  data?: NewsRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<NewsResponse>> => {
  const { baseUrl = '' } = options ?? {};

  return await request({
    url: '/api/news',
    baseUrl,
    method: HTTPMethods.GET,
    params: {
      ...data
    }
  });
};
