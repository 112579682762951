import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { type GetHackClubRequest, type GetHackClubResponse } from './types';

export const getHackClub = async (
  data?: GetHackClubRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetHackClubResponse>> => {
  const { baseUrl = '' } = options ?? {};

  return await request({
    url: '/api/clubs/hack',
    baseUrl,
    method: HTTPMethods.GET,
    params: {
      ...data
    }
  });
};
