import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Button, Flex, Heading, Icon, Spacer, Text } from '@uiKit';

import { ButtonSize, ButtonVariant } from '@uiKit/Button/types';
import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize, HeadingType } from '@uiKit/Heading/types';
import { Images } from '@uiKit/Icon/constants/images';
import { IconSize } from '@uiKit/Icon/types';
import { ImageObjectFit } from '@uiKit/Image/types';
import { TextSize } from '@uiKit/Text/types';

import designImagePng from '@assets/images/clubs/design/design.png';
import designImageWebP from '@assets/images/clubs/design/design.webp';
import designImageHoverPNG from '@assets/images/clubs/design/design_hover.png';
import designImageHoverWebP from '@assets/images/clubs/design/design_hover.webp';
import { AchievementsSection } from '@component/AchievementsSection';
import { LoaderScreen } from '@component/LoaderScreen';
import { ProjectCard } from '@component/ProjectCard';
import { ProjectList } from '@component/ProjectList';
import { useAPI } from '@hooks/useAPI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { PageLayout } from '@layouts/PageLayout';
import { getAchievements, getDesignClub, getProjects } from '@lib/api';
import { ClubsHead } from '@pages/Clubs/components/ClubsHead/ClubsHead';
import { Styled } from '@pages/Clubs/pages/Hackathon/styled';

export const DesignClub: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  const { data, isLoading } = useAPI({ apiService: getDesignClub }, { runOnMount: true });
  const { data: projects, isLoading: isLoadingProjects } = useAPI({ apiService: getProjects, requestData: { club: 'design' } }, { runOnMount: true });
  const { data: achievements, isLoading: isLoadingAchievements } = useAPI({ apiService: getAchievements, requestData: { club: 'design' } }, { runOnMount: true });

  return (
    isLoading || isLoadingProjects || isLoadingAchievements ? <LoaderScreen /> : (
      <PageLayout>
        <Spacer space={theme.spacings.x128 + theme.spacings.x128} />
        <Styled.HeadWrapper>
          <Styled.Heading align={HeadingAlign.CENTER}>Дизайн клуб</Styled.Heading>
          <Styled.ImageWrapper>
            <Styled.Image objectFit={ImageObjectFit.CONTAIN} fullWidth fullHeight alt='Хакатон клуб' images={[{ type: 'webp', image: designImageHoverWebP, isPreferable: true }, { type: 'png', image: designImageHoverPNG }]} />
            <Styled.ImageFront objectFit={ImageObjectFit.CONTAIN} fullWidth fullHeight alt='Хакатон клуб' images={[{ type: 'webp', image: designImageWebP, isPreferable: true }, { type: 'png', image: designImagePng }]} />
          </Styled.ImageWrapper>
        </Styled.HeadWrapper>
        <Spacer space={theme.spacings.x128 + theme.spacings.x128} />
        <Flex direction={FlexDirection.COLUMN} gap={isMobile ? theme.spacings.x64 : theme.spacings.x128}>
          <Flex direction={isMobile ? FlexDirection.COLUMN_REVERSE : FlexDirection.ROW} gap={isMobile ? theme.spacings.x32 : theme.spacings.x128}>
            <Flex justifyContent={FlexJustifyContent.CENTER} flex='1' direction={FlexDirection.COLUMN}>
              <Heading type={HeadingType.H3} size={HeadingSize.MD}>{data?.info[0].name}</Heading>
              <Spacer space={theme.spacings.x24} />
              <Text size={TextSize.M1} color={theme.colors.base.grey['400']}>
                {data?.info[0].desc}
              </Text>
            </Flex>
            {data?.info[0].image && (
              <Flex flex='1' alignItems={FlexAlignItems.CENTER}>
                <Styled.ContainerImage radius={theme.radiuses.md} fullWidth fullHeight alt='Hack' images={[{ type: 'webp', image: data?.info[0].image, isPreferable: true }]} />
              </Flex>
            )}
          </Flex>
          <Flex direction={isMobile ? FlexDirection.COLUMN : FlexDirection.ROW} gap={isMobile ? theme.spacings.x32 : theme.spacings.x128}>
            {data?.info[1].image && (
              <Flex flex='1'>
                <Styled.ContainerImage radius={theme.radiuses.md} fullWidth fullHeight alt='Hack' images={[{ type: 'webp', image: data?.info[1].image, isPreferable: true }]} />
              </Flex>
            )}
            <Flex justifyContent={FlexJustifyContent.CENTER} flex='1' direction={FlexDirection.COLUMN}>
              <Heading type={HeadingType.H3} size={HeadingSize.MD}>{data?.info[1].name}</Heading>
              <Spacer space={theme.spacings.x24} />
              <Text size={TextSize.M1} color={theme.colors.base.grey['400']}>
                {data?.info[1].desc}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Spacer space={theme.spacings.x128} />
        {projects?.projects && projects?.projects?.length > 0 ? (
          <>
            <section>
              <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER}>Проекты</Heading>
              <Spacer space={theme.spacings.x128} />
              <ProjectList>
                {projects?.projects.map(({ image, title, badge }, index) => <ProjectCard key={index} images={[{ type: 'webp', image }]} title={title} tag={badge} />)}
              </ProjectList>
            </section>
            <Spacer space={theme.spacings.x128} />
          </>
        ) : <></>}
        <AchievementsSection achievements={achievements?.achievements} />
        <Spacer space={theme.spacings.x128} />
        <section>
          <Heading type={HeadingType.H2} size={HeadingSize.LG} align={HeadingAlign.CENTER}>Руководители</Heading>
          <Spacer space={theme.spacings.x128} />
          <Flex gap={theme.spacings.x128} alignItems={FlexAlignItems.CENTER} direction={FlexDirection.COLUMN} justifyContent={FlexJustifyContent.CENTER}>
            <Flex direction={isMobile ? FlexDirection.COLUMN : FlexDirection.ROW} fullWidth gap={theme.spacings.x24} justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
              {data?.head.map(({ name, post, image, url }, index) => <ClubsHead key={index} image={image} subtitle={post} name={name} socialLinks={[{ icon: Images.TG, href: url }]} />)}
            </Flex>
            <Button role='link' onClick={() => location.href = 'https://l.itatmisis.ru/design'} variant={ButtonVariant.PRIMARY} size={ButtonSize.LG}>
              <Flex gap={theme.spacings.x24}>
                Вступить в клуб
                <Icon size={IconSize.X20} image={Images.ARROW_RIGHT} />
              </Flex>
            </Button>
          </Flex>
        </section>
        <Spacer space={theme.spacings.x128} />
      </PageLayout>
    )
  );
};
