import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { type AchievementsRequest, type AchievementsResponse } from './types';

export const getAchievements = async (
  data?: AchievementsRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<AchievementsResponse>> => {
  const { baseUrl = '' } = options ?? {};

  return await request({
    url: '/api/achievements',
    baseUrl,
    method: HTTPMethods.GET,
    params: {
      ...data
    }
  });
};
