import React, { type FC } from 'react';

import { useTheme } from 'styled-components';

import { Heading, Spacer } from '@uiKit';

import { HeadingAlign, HeadingSize } from '@uiKit/Heading/types';

import { LoaderScreen, ProjectCard, ProjectList } from '@component/index';
import { useAPI } from '@hooks/useAPI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { PageLayout } from '@layouts/PageLayout';
import { getProjects } from '@lib/api';

export const Projects: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  const { data, isLoading } = useAPI({ apiService: getProjects }, { runOnMount: true });

  return (
    isLoading ? <LoaderScreen /> : (
      <PageLayout>
        <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
        <Heading size={HeadingSize.LG} align={HeadingAlign.CENTER}>Проекты</Heading>
        <Spacer space={isMobile ? theme.spacings.x36 : theme.spacings.x128} />
        <ProjectList>
          {data?.projects.map(({ image, title, badge }, index) => <ProjectCard key={index} images={[{ type: 'webp', image, isPreferable: true }]} title={title} tag={badge} />)}
        </ProjectList>
        <Spacer space={theme.spacings.x128 + theme.spacings.x128} />
      </PageLayout>
    )
  );
};
