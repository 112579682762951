import React, { type FC, useEffect, useMemo, useState } from 'react';

import { useTheme } from 'styled-components';

import { Divider, Flex, Heading, Spacer } from '@uiKit';

import { FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types';
import { HeadingAlign, HeadingSize } from '@uiKit/Heading/types';

import { RadioGroup, RadioText, NewsCard, LoaderScreen } from '@component/index';
import { NewsCardDir } from '@component/NewsCard/types';
import { useAPI } from '@hooks/useAPI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { PageLayout } from '@layouts/PageLayout';
import { getNews } from '@lib/api';

export const News: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);
  const { data, isLoading } = useAPI({ apiService: getNews }, { runOnMount: true });

  const years = useMemo(() => [...new Set(data?.news.map(({ year }) => year).sort())], [data?.news]);

  const [selectedYear, setSelectedYear] = useState(0);

  useEffect(() => {
    if (!selectedYear && years) {
      setSelectedYear(years[years.length - 1]);
    }
  }, [years, selectedYear]);
  const onChange = (value: string) => { setSelectedYear(parseInt(value)); };

  const news = data?.news.filter((news) => news.year === selectedYear);

  return (
    isLoading ? <LoaderScreen /> : (
      <PageLayout>
        <Spacer space={theme.spacings.x128} />
        <Heading size={isMobile ? HeadingSize.DF : HeadingSize.LG} align={HeadingAlign.CENTER}>Упоминание в СМИ</Heading>
        <Spacer space={theme.spacings.x24} />
        <Flex justifyContent={FlexJustifyContent.CENTER}>
          <RadioGroup name='year' value={selectedYear} onChange={onChange} gap={theme.spacings.x36}>
            {years ? years?.map((year, index) => (
              <RadioText key={index} value={year}>
                {year}
              </RadioText>
            )) : <></>}
          </RadioGroup>
        </Flex>
        <Spacer space={theme.spacings.x128} />
        <Flex direction={FlexDirection.COLUMN}>
          {news?.map(({ title, sources, image, url }, index) => (
            <>
              <Divider />
              <Spacer space={theme.spacings.x36} />
              <NewsCard url={url} dir={NewsCardDir.LTR} title={title} sources={sources} images={[{ type: 'webp', image, isPreferable: true }]} />
              <Spacer space={theme.spacings.x36} />
              {index === news.length - 1 && <Divider />}
            </>
          ))}
        </Flex>
        <Spacer space={theme.spacings.x128 + theme.spacings.x128} />
      </PageLayout>
    )
  );
};
