import React from 'react';

import { type RouteObject } from 'react-router-dom';

import { AIClub } from '@pages/Clubs/pages/AI/AI';
import { DesignClub } from '@pages/Clubs/pages/Design/Design';
import { GameClub } from '@pages/Clubs/pages/Game/Game';
import { HackathonClub } from '@pages/Clubs/pages/Hackathon/Hackathon';
import { RoboticsClub } from '@pages/Clubs/pages/Robotics/Robotics';

import { CLUBS_ROUTE } from './constants';

export const clubsRoutes: RouteObject[] = [
  {
    path: CLUBS_ROUTE.hackathon.path,
    element: (
        <HackathonClub />
    )
  },
  {
    path: CLUBS_ROUTE.design.path,
    element: (
      <DesignClub />
    )
  },
  {
    path: CLUBS_ROUTE.robotics.path,
    element: (
      <RoboticsClub />
    )
  },
  {
    path: CLUBS_ROUTE.ai.path,
    element: (
      <AIClub />
    )
  },
  {
    path: CLUBS_ROUTE.game.path,
    element: (
      <GameClub />
    )
  }
];
