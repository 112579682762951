import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { type HomeRequest, type HomeResponse } from './types';

export const getHome = async (
  data?: HomeRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<HomeResponse>> => {
  const { baseUrl = '' } = options ?? {};

  return await request({
    url: '/api/home',
    baseUrl,
    method: HTTPMethods.GET,
    params: {
      ...data
    }
  });
};
