import React, { type FC, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Spacer } from '@uiKit';

import { AchievementsSection } from '@component/AchievementsSection';
import { LoaderScreen } from '@component/LoaderScreen';
import { useAPI } from '@hooks/useAPI';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { PageLayout } from '@layouts/PageLayout';
import { getAchievements, getHome, getNews, getProjects, getSocial, getTeam } from '@lib/api';
import {
  AboutSection,
  ClubsSection,
  MainSection,
  PartnersSection,
  ProjectsSection, SocialSection,
  StatisticsSection, TeamSection
} from '@pages/Home/components';

export const Home: FC = () => {
  const theme = useTheme();
  const isMobile = useBreakpoint(theme.breakpoints.mobile);

  const location = useLocation();

  useEffect(() => {
    location.hash && document.querySelector(location.hash)?.scrollIntoView();
  }, [location.hash]);

  const { data, isLoading } = useAPI({ apiService: getHome }, { runOnMount: true });
  const { data: projects, isLoading: isLoadingProjects } = useAPI({ apiService: getProjects, requestData: { count: 4 } }, { runOnMount: true });
  const { data: news, isLoading: isLoadingNews } = useAPI({ apiService: getNews, requestData: { count: 3 } }, { runOnMount: true });
  const { data: achievements, isLoading: isLoadingAchievements } = useAPI({ apiService: getAchievements, requestData: { count: 5 } }, { runOnMount: true });
  const { data: team, isLoading: isLoadingTeam } = useAPI({ apiService: getTeam }, { runOnMount: true });
  const { data: social, isLoading: isLoadingSocial } = useAPI({ apiService: getSocial }, { runOnMount: true });

  const statistics = data?.statistics;
  const clubs = data?.clubs;

  const projectsData = useMemo(() => projects?.projects.map(({ title, image, badge }) => ({ title, tag: badge, image })), [projects?.projects]);
  const newsData = useMemo(() => news?.news.map(({ title, image, sources, url }) => ({ title, sources, image, url })), [news?.news]);
  const teamData = useMemo(() => team?.team.map(({ name, image, post }) => ({ name, image, post })), [team?.team]);

  return (
    isLoading || isLoadingProjects || isLoadingNews || isLoadingAchievements || isLoadingTeam || isLoadingSocial ? <LoaderScreen /> : (
      <PageLayout>
        <MainSection title={data?.main.title} subtitle={data?.main.subtitle} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <StatisticsSection clubsOne={{ title: statistics?.[0].title, subtitle: statistics?.[0].subtitle }} clubsTwo={{ title: statistics?.[1].title, subtitle: statistics?.[1].subtitle }} welcomeSection={{ title: statistics?.[5].title, subtitle: statistics?.[5].subtitle }} projectOne={{ title: statistics?.[4].title, subtitle: statistics?.[4].subtitle }} projectTwo={{ title: statistics?.[3].title, subtitle: statistics?.[3].subtitle }} quote={{ title: statistics?.[2].title }} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <ProjectsSection projects={projectsData} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <PartnersSection subtitle={data?.partners.subtitle} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <ClubsSection hackClub={{ title: clubs?.[2]?.name, tags: clubs?.[2].tags }} roboticsClub={{ title: clubs?.[0]?.name, tags: clubs?.[0].tags }} gamedevClub={{ title: clubs?.[1]?.name, tags: clubs?.[1].tags }} designClub={{ title: clubs?.[3]?.name, tags: clubs?.[3].tags }} aiClub={{ title: clubs?.[4]?.name, tags: clubs?.[4].tags }} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <AboutSection news={newsData} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <AchievementsSection achievements={achievements?.achievements} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <TeamSection team={teamData} />
        <Spacer space={isMobile ? theme.spacings.x64 : theme.spacings.x128} />
        <SocialSection social={social?.social} />
        <Spacer space={isMobile ? theme.spacings.x128 : theme.spacings.x128 * 2} />
      </PageLayout>
    )
  );
};
