export const CLUBS_ROUTE = {
  hackathon: {
    path: '/clubs/hackathon'
  },
  design: {
    path: '/clubs/design'
  },
  robotics: {
    path: '/clubs/robotics'
  },
  game: {
    path: '/clubs/game'
  },
  ai: {
    path: '/clubs/ai'
  }
};
