import { request } from '@http';
import { type AxiosResponse } from 'axios';

import { type APIFunctionOptions } from '@lib/api/types';
import { HTTPMethods } from '@lib/http/constants';

import { type GetRoboticsClubRequest, type GetRoboticsClubResponse } from './types';

export const getRoboticsClub = async (
  data?: GetRoboticsClubRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetRoboticsClubResponse>> => {
  const { baseUrl = '' } = options ?? {};

  return await request({
    url: '/api/clubs/robotics',
    baseUrl,
    method: HTTPMethods.GET,
    params: {
      ...data
    }
  });
};
